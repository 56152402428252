<template>
    <div class="baseTemplate_container">
        <CustomImage :src="data.content" />
    </div>
</template>
<script>
import CustomImage from '@/components/unit/customImage'
export default {
    name:"baseTemplate",
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            data:{
                content:""
            }
        }
    },
    components:{
        CustomImage
    },
   created(){
        this.initData()
    },
    methods:{
        initData(){
            let keys = Object.keys(this.data)
            keys.forEach((key,index)=>{
                this.data[key] =  this.pageItem.content[index].original_image
            })
        },
        doEdit(){},
        clearInfo(){},
        getData(callback){
            callback({code:301})
        }
    }
}
</script>
<style>
.baseTemplate_container{
    height:100%;
    width:100%;
}
.baseTemplate_container img{
    height:100%;
    width:100%;
    max-width: 100%;
}
</style>